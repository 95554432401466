import React, { Suspense } from 'react';
// import '../i18n';
import { Layout, RegistrationLayout, PageLayout } from '../components';
import { graphql } from 'gatsby';

const RegistrationPage = ({ location }) => {
  return (
    // <Suspense fallback="loading">
    <Layout location={location}>
      <PageLayout location={location}>
        <RegistrationLayout />
      </PageLayout>
    </Layout>
    // </Suspense>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default RegistrationPage;
